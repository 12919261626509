body {
  color: $secondary;
  background: transparent;
}

@font-face {
  font-family: 'LeaderboardFont';
  src: url('/fonts/bebasneue.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Black.ttf') format('ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoThin';
  src: url('/fonts/Roboto-Medium.ttf') format('ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'agency_fb';
  src: url('/assets/fonts/Agency-FB.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'big_noodle_titling';
  src: url('/assets/fonts/big-noodle-titling.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'bebas_neue';
  src: url('/assets/fonts/BebasNeue.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'muli';
  src: url('/assets/fonts/muli.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'ael';
  src: url('/assets/fonts/ael.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'ael-title';
  src: url('/assets/fonts/ael-title.woff') format('woff');
}

@font-face {
  font-family: 'ael-subtitle';
  src: url('/assets/fonts/ael-subtitle.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'utm-alexander';
  src: url('/assets/fonts/UTM-Alexander.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'osl5';
  src: url('/assets/fonts/osl5.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'osl5-2';
  src: url('/assets/fonts/osl5-2.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'osl5-3';
  src: url('/assets/fonts/osl5-3.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'RussoOne-Regular';
  src: url('/assets/fonts/RussoOne-Regular.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('/assets/fonts/Montserrat-Bold.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gobold-Thin';
  src: url('/assets/fonts/Gobold-Thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SamsungOne';
  src: url('/assets/fonts/SamsungOne.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'BarlowCondensed-Regular';
  src: url('/assets/fonts/BarlowCondensed-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'BarlowCondensed-Light';
  src: url('/assets/fonts/BarlowCondensed-Light.woff2') format('woff2');
}
