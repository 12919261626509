.animate-in-left {
  animation-name: animation-in-left;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.96, 0.05, 0.14, 0.92);
}

.animate-in-right {
  animation-name: animation-in-right;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.96, 0.05, 0.14, 0.92);
}

.animate-out-left {
  animation-name: animation-out-left;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
}

.animate-out-right {
  animation-name: animation-out-right;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
}

@keyframes animation-in-left {
  0% {
    transform: translateX(-200%);
  }
  75% {
    transform: translateX(25%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes animation-out-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

@keyframes animation-in-right {
  0% {
    transform: translateX(200%);
  }
  75% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes animation-out-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}
