// Elementary colors - use those prior to color variables if possible
$primary: #F2AA04;
$primaryLight: #FEC33E;
$primaryShadow: rgba(239, 168, 40, 0.4);
$primaryGlow: #FEC33E;
$secondary: #FFFFFF;
$tertiary: #1C1D22;
$tertiaryDark: #161719;
$tertiaryTransparent: #1C1D22D8;
$warning: #F28204;
$danger: #F4512D;
$success: #209191;
$info: #989AA2;
$infoDark: #5B6073;
$transparent: #00000000;
$discord: rgb(114, 137, 218);
$twitter: rgb(29, 161, 242);

// Balkan Liga colors
$balkanBlue: #0587cd;
// 303 colors
$green303: #0bba93;
// PSL color
$pslColor: #0064ff;
// PCS color
$pcsColor: #0185c8;
// BAE color
$baeColor: #ee2c43;
// AEL colors
$aelBlue: #43dae9;
$aelGreen: #69fca2;
// 1% colors
$optColor: #1e98d4;
// eu scrim colors
$euColor: #00349a;
